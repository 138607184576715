import React from 'react';
import { Link } from 'gatsby';
import Header from './header.component.en';
import FooterComponentEN from './footer.component.en';
import CookieConsent from 'react-cookie-consent';
import '../styles/layout.scss';

export default function LayoutEN({ children }) {
	return (
		<div className='website'>
			<Header />
			<main>{children}</main>
			<CookieConsent
				location='bottom'
				buttonText='Aceptar'
				declineButtonText='Rechazar'
				cookieName='gdpr-google-tagmanager'
				buttonStyle={{
					borderRadius: '10px',
					background: 'rgb(0, 153, 255)',
					color: 'white',
					padding: '10px',
				}}
			>
				We use cookies to analyze traffic, nothing else. You can see it written
				down (with that simple and plain style that characterizes the legal
				texts) in the <Link to='/en/cookies'>Privacy Policy</Link>.
			</CookieConsent>
			<FooterComponentEN />
		</div>
	);
}
