import React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Seo from '../../components/seo.en';
import LayoutEN from '../../components/layout.en';
import LayoutES from '../../components/layout.es';
import LatestPostsEN from '../../components/latestposts.component.en';
import LatestPostsES from '../../components/latestposts.component.es';
import '../../styles/post.scss';

const BlogPost = ({ data }) => {
	const image = getImage(data.mdx.frontmatter.hero_image);

	if (data.mdx.frontmatter.lang === 'es') {
		return (
			<LayoutES>
				<Seo title={data.mdx.frontmatter.title} />
				<div className='blog-post'>
					<h1>{data.mdx.frontmatter.title}</h1>
					<p>
						Publicado el {data.mdx.frontmatter.date} por{' '}
						<a href={data.mdx.frontmatter.author_url}>
							{data.mdx.frontmatter.author}
						</a>
					</p>
					<GatsbyImage
						image={image}
						alt={data.mdx.frontmatter.hero_image_alt}
					/>
					<p style={{ fontSize: '0.8rem', textAlign: 'center' }}>
						Credits:{' '}
						<a
							href={data.mdx.frontmatter.hero_image_credit_link}
							target='_blank'
						>
							{data.mdx.frontmatter.hero_image_credit_text}
						</a>
					</p>
					<MDXRenderer>{data.mdx.body}</MDXRenderer>
				</div>
				<LatestPostsES />
			</LayoutES>
		);
	}
	else {
		return (
			<LayoutEN>
				<Seo title={data.mdx.frontmatter.title} />
				<div className='blog-post'>
					<h1>{data.mdx.frontmatter.title}</h1>
					<p>
						Published {data.mdx.frontmatter.date} by{' '}
						<a href={data.mdx.frontmatter.author_url}>
							{data.mdx.frontmatter.author}
						</a>
					</p>
					<GatsbyImage
						image={image}
						alt={data.mdx.frontmatter.hero_image_alt}
					/>
					<p style={{ fontSize: '0.8rem', textAlign: 'center' }}>
						Credits:{' '}
						<a href={data.mdx.frontmatter.hero_image_credit_link}>
							{data.mdx.frontmatter.hero_image_credit_text}
						</a>
					</p>
					<MDXRenderer>{data.mdx.body}</MDXRenderer>
				</div>
				<LatestPostsEN />
			</LayoutEN>
		);
	}
};

export const query = graphql`
	query ($id: String) {
		mdx(id: { eq: $id }) {
			frontmatter {
				title
				author
				author_url
				lang
				date(formatString: "DD-MM-YYYY")
				hero_image_alt
				hero_image_credit_link
				hero_image_credit_text
				hero_image {
					childImageSharp {
						gatsbyImageData
					}
				}
			}
			body
		}
	}
`;

export default BlogPost;
