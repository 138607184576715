import React from "react";
import FooterSubsidy from "./footer-subsidy.component";
import { Link } from "gatsby";
import logoUrl from "../static/images/logo-kuflow-invertido.svg";

import "../styles/footer.scss";

export default function FooterComponentEN() {
  return (
    <div className='footer-area'>
      <footer className='footer'>
        <div className='footer-container'>
          <div className='logo-container'>
            <img src={logoUrl} alt='Logo KuFlow invertido' />
          </div>
          <div className='nav-items'>
            <div className='nav-item'>
              <a href='mailto:kuflow@kuflow.com'>Contact us</a>
            </div>
            <div className='nav-item'>
              <Link to='/en/terms'>Terms and conditions</Link>
            </div>
            <div className='nav-item'>
              <Link to='/en/transparency'>Transparency</Link>
            </div>
            <div className='nav-item'>
              <Link to='/en/legal'>Legal</Link>
            </div>
          </div>
        </div>
      </footer>
      <FooterSubsidy />
    </div>
  );
}
