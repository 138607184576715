import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import '../styles/header.scss';
import logoUrl from '../static/images/logo-kuflow.svg';

export default function Header() {
	const [scroll, setScroll] = useState(false);

	useEffect(() => {
		window.addEventListener('scroll', () => {
			setScroll(window.scrollY > 50);
		});
	}, []);

	return (
		<header className={scroll ? 'shadow header' : 'header'}>
			<div className='header-container'>
				<div className='logo-container'>
					<a href='/'>
						<img src={logoUrl} alt='Logo KuFlow' />
					</a>
				</div>
				<div className='navbar navbar-desktop'>
					<div className='dropdown'>
						<button className='dropbtn'>Solutions ▼</button>
						<div className='dropdown-content'>
							<Link to='/en/solution-workflow'>Workflows</Link>
							<Link to='/en/solution-rpa'>Smart automation</Link>
							<Link to='/en/solution-orchestration'>Service orchestration</Link>
						</div>
					</div>

					<div className='dropdown'>
						<button className='dropbtn'>Product ▼</button>
						<div className='dropdown-content'>
							<Link to='/en/product'>Product</Link>
							<Link to='/en/faq'>In detail</Link>
						</div>
					</div>
					<div className='dropdown'>
						<button className='dropbtn'>Resources ▼</button>
						<div className='dropdown-content'>
							<Link to='/en/blog'>Blog</Link>
							<a href='https://docs.kuflow.com/'>Docs</a>
							<a href='https://community.kuflow.com/'>Forum</a>
							<a href='https://status.kuflow.com/'>Status of services</a>
						</div>
					</div>
					<Link to='/en/jobs'>Work with us</Link>
					<a href='https://app.kuflow.com/' className='kuflow-signin'>
						Sign in
					</a>
				</div>
				<div className='navbar navbar-mobile'>
					<div className='dropdown'>
						<button className='dropbtn'>Menu ▼</button>
						<div className='dropdown-content'>
							<Link to='/en/solution-workflow'>Solutions &gt; Workflows</Link>
							<Link to='/en/solution-rpa'>Solutions &gt; Smart automation</Link>
							<Link to='/en/solution-orchestration'>
								Solutions &gt; Service orchestration
							</Link>
							<Link to='/en/product'>Product &gt; Product</Link>
							<Link to='/en/faq'>Product &gt; In detail</Link>
							<Link to='/en/blog'>Resources &gt; Blog</Link>
							<a href='https://docs.kuflow.com/'>Resources &gt; Docs</a>
							<a href='https://community.kuflow.com/'>Resources &gt; Forum</a>
							<a href='https://status.kuflow.com/'>Resources &gt; Status of services</a>
							<Link to='/en/jobs'>Work with us</Link>
							<a href='https://app.kuflow.com/' className='kuflow-signin'>
								Sign in
							</a>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
}
