import React from 'react';
import { useStaticQuery, Link, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import '../styles/latestposts.scss';

const LatestPostsES = () => {
	const data = useStaticQuery(graphql`
		query {
			allMdx(
				limit: 4
				sort: { fields: frontmatter___date, order: DESC }
				filter: { frontmatter: { lang: { eq: "es" } } }
			) {
				nodes {
					frontmatter {
						date(formatString: "MMMM D, YYYY")
						title
						hero_image_alt
						hero_image {
							childImageSharp {
								gatsbyImageData(width: 300, height: 200)
							}
						}
					}
					id
					slug
				}
			}
		}
	`);

	return (
		<div id='articles'>
			<h2>KuBlog</h2>
			<p>Últimos artículos</p>
			<div className='article_columns'>
				{data.allMdx.nodes.map((node) => (
					<div className='article_column' key={node.id}>
						<Link to={`/blog/${node.slug}`}>
							<GatsbyImage
								image={
									node.frontmatter.hero_image.childImageSharp.gatsbyImageData
								}
								alt={node.frontmatter.hero_image_alt}
								// objectFit='contain'
								className='article-pic'
							/>
							<div className='article-desc'>
								<h3>{node.frontmatter.title}</h3>
							</div>
						</Link>
					</div>
				))}
			</div>
		</div>
	);
};

export default LatestPostsES;
